<template>
    <GLText
        ref="refText"
        :text="text"
        :layer="layer"
        :options="textOptions"
        @sync="onTextSync"
    ></GLText>
</template>

<script setup>
    import { Mesh } from 'three';
    import { computed, onMounted, reactive, ref, watchEffect } from 'vue';

    import { simpleMaterial, simplePlaneGeometry } from '@resn/gozer-three';
    import { useThreeObject } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import GLText from '~/components/gl/GLText.vue';
    import { IN_DUR, LAYER_BG, OUT_DUR, OUT_START, TOTAL_DUR } from '~/core/constants';

    const props = defineProps({
        debug: { default: true },
        layer: { default: LAYER_BG },
        text: { default: 'Team Info' },
        bounds: { default: { width: 1, height: 1 } },
        position: { default: { x: 0, y: 0 } },
        sign: { default: 1 },
        mask: { default: false },
    });

    const refText = ref(null);

    const { object } = useThreeObject(null, { name: 'QuoteLine' });
    const { object: innerObject } = useThreeObject(null, {
        name: 'QuoteLineInner',
        addToParent: false,
    });

    const bg = new Mesh(simplePlaneGeometry, simpleMaterial('red'));
    bg.layers.set(props.layer);
    bg.material.opacity = 0.4;

    object.add(innerObject);
    innerObject.add(bg);

    const fontSize = computed(() => props.bounds.height * 1.2);

    const textOptions = reactive({
        fontSize,
        anchorX: props.sign === 0 ? 'center' : props.sign > 0 ? 'left' : 'right',
        anchorY: 'center',
        color: '#ffffff',
    });

    onMounted(() => {
        const { material } = refText.value.mesh;
        material.defines.USE_MASK = props.mask ? 1 : 0;
        material.needsUpdate = true;
    });

    const onTextSync = () => {
        updateLetters();
        setBounds();
    };
    const updateLetters = () => {
        refText.value.updateLetters();
    };

    const show = ({ delay = 0, type = 'horizontal' } = {}) => {
        const tl = type === 'horizontal' ? getAnimationX({ delay }) : getAnimationY({ delay });
        return tl;
    };

    const getAnimationX = ({ delay = 0 } = {}) => {
        const { object, realSize, letters } = refText.value;
        const { bounds, sign } = props;

        const ratio = 800 / bounds.height;

        const xFr = props.bounds.width * sign;
        const xTo = -props.bounds.width * sign;

        const ambDist = realSize.width * 0.225 * ratio * sign;
        const ambDur = TOTAL_DUR * 1.1;

        const letterDist = bounds.width * 0.2;
        const lettersPosArr = letters.map((letter) => letter.position);

        const tl = gsap
            .timeline({ delay, onUpdate: updateLetters })
            .fromTo(
                innerObject.position,
                { x: 0 },
                { x: -ambDist, duration: ambDur, ease: 'none' },
                0
            )
            .fromTo(object.position, { x: xFr }, { x: 0, duration: IN_DUR, ease: 'power3.out' }, 0)
            .to(object.position, { x: xTo, duration: OUT_DUR, ease: 'power3.in' }, OUT_START)
            .fromTo(
                letters,
                { alpha: 0 },
                {
                    alpha: 1,
                    duration: 0.2,
                    ease: 'sine.out',
                    stagger: { each: 0.02, from: sign > 0 ? 'start' : 'end' },
                },
                0
            )
            .fromTo(
                lettersPosArr,
                { x: letterDist * sign },
                {
                    x: 0,
                    duration: 1,
                    ease: 'power2.out',
                    stagger: { each: 0.02, from: sign > 0 ? 'start' : 'end' },
                },
                0
            )
            .to(
                lettersPosArr,
                {
                    x: letterDist * sign,
                    duration: 0.7,
                    ease: 'power2.in',
                    stagger: { each: 0.02, from: sign > 0 ? 'end' : 'start' },
                },
                OUT_START
            )
            .set(letters, { alpha: 0 });

        return tl;
    };

    const getAnimationY = ({ delay = 0 } = {}) => {
        const { bounds, sign } = props;

        const { letters } = refText.value;
        const letterDist = bounds.height * 2;
        const lettersPosArr = letters.map((letter) => letter.position);

        const tl = gsap
            .timeline({ delay, onUpdate: updateLetters })
            .fromTo(
                lettersPosArr,
                { y: -letterDist },
                {
                    y: 0,
                    duration: 1,
                    ease: 'power2.out',
                    stagger: { each: 0.04 },
                },
                0
            )
            .to(
                lettersPosArr,
                {
                    y: -letterDist,
                    duration: 0.7,
                    ease: 'power2.in',
                    stagger: { each: 0.04, from: 'end' },
                },
                OUT_START
            )
            .set(letters, { alpha: 0 });

        return tl;
    };

    const setFontSize = (val) => {
        // innerObject.position.y = fontSize.value * 0.05;
    };

    const setPosition = () => {
        const { x, y } = props.position;
        object.position.set(x, y, 0);
    };

    const setBounds = () => {
        if (refText.value) {
            const { realSize } = refText.value;
            const { width, height } = props.bounds;
            const bgHeight = height * 0.95;

            bg.scale.set(realSize.width, bgHeight, 1);
            bg.position.set((realSize.width / 2) * props.sign, -bgHeight / 2, 0);
        }
        // bg.scale.set(width, bgHeight, 1);
        // bg.position.set(width / 2, -bgHeight / 2, 0);
        // if (props.sign < 0) bg.position.x = -width / 2;
    };

    const setDebug = () => {
        bg.visible = props.debug;
    };

    watchEffect(setPosition);
    watchEffect(setBounds);
    watchEffect(setDebug);
    watchEffect(setFontSize);

    defineExpose({ show });
</script>
