import { Object3D } from 'three';
import { inject, onUnmounted, provide } from 'vue';

export const useCutawayObject = (obj, { addToParent = true, name = 'Object' } = {}) => {
    const object = obj || new Object3D();
    object.name = name;

    if (addToParent) {
        const { object: cutawayParent } = inject('cutaway', {});
        const addTo = inject('addTo');

        if (cutawayParent) cutawayParent.add(object);
        else addTo.add(object);
    }

    onUnmounted(() => {
        object.parent?.remove(object);
    });

    provide('addTo', object);

    return { object };
};
