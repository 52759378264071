<template>
    <SimplePlane
        ref="refQuad"
        :material="material"
        :layer="LAYER_FG"
        :visible="canRender"
        :addToParent="false"
    />
    <slot />
</template>

<script setup>
    import { useThreeOrthoObject } from '#imports';
    import { Color, Scene, Vector2, WebGLRenderTarget } from 'three';
    import { computed, onBeforeUnmount, onMounted, provide, ref, watchEffect } from 'vue';

    import glslToRadians from '@resn/gozer-glsl/math/to-radians.glsl';
    import { CustomMaterial } from '@resn/gozer-three/materials';
    import { usePane, useRafBool, useRenderer, useViewportResize } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import { shaderChunks as glslIrridescentNoise } from '@/components/gl/shaders/ShaderChunks/irridescent-noise.js';
    import { neutralTonemappingChunks as glslNeutralTonemapping } from '@/components/gl/shaders/ShaderChunks/neutral-tonemapping.js';
    import glslBrightnessContrast from '@/libs/lygia/color/brightnessContrast.glsl';
    import glslGamma2Linear from '@/libs/lygia/color/space/gamma2linear.glsl';
    import glslRatio from '@/libs/lygia/space/ratio.glsl';
    import glslRotate from '@/libs/lygia/space/rotate.glsl';
    import glslScale from '@/libs/lygia/space/scale.glsl';
    import SimplePlane from '~/components/gl/common/SimplePlane.vue';
    import { IN_DUR, IN_EASE, LAYER_FG, OUT_DUR, OUT_EASE, PALETTE_THEME } from '~/core/constants';

    const inPr = ref(0);
    const outPr = ref(0);

    const refQuad = ref();

    const { renderer, scene, orthoCamera } = useRenderer();

    // All cutaway scenes will be added to this scene
    const sceneCutaway = new Scene();
    sceneCutaway.name = 'CutawayScene';
    const fbo = new WebGLRenderTarget();

    provide('cutaway', { object: sceneCutaway });

    onMounted(() => {
        scene.add(refQuad.value.mesh);
    });

    const canRender = computed(() => inPr.value > 0 && outPr.value < 1);

    const material = new CustomMaterial({
        uniforms: {
            ...{
                tMap: fbo.texture,

                u_prIn: 0,
                u_prOut: 0,
                u_time: 0,
                u_resolution: new Vector2(),

                u_colorBG: new Color('#000'),
                u_palette_noise: PALETTE_THEME[0].map((c) => new Color(c)),
            },
        },
        fs: /* glsl */ `
            varying vec2 vUv;

            uniform sampler2D tMap;
            
            uniform float u_prIn;
            uniform float u_prOut;
            uniform vec3 u_colorBG;
            uniform vec2 u_resolution;
            uniform float u_time;

            #define NUM_COLORS 3
            
            ${glslIrridescentNoise.uniforms}
            
            ${glslNeutralTonemapping.funcsVariant}
            ${glslIrridescentNoise.funcs}
            
            ${glslBrightnessContrast}
            ${glslGamma2Linear}
            ${glslRotate}
            ${glslScale}
            ${glslRatio}
            ${glslToRadians}

            void main() {
                vec2 st = vUv;
                st = ratio(st, u_resolution.xy);

                vec2 stN = st;
                stN = scale(stN, 0.4);
                stN.xy -= u_time * 0.2;
                
                float n = snoise(stN) * 1.2;
                      n = n * .5 + .5;
                
                vec2 st0 = st;
                st0 += n;
                st0 = rotate(st0, -0.7);

                float feather = 0.7;
                float featherHalf = feather * 0.5;
                
                float distFromEdge = mix(featherHalf, 1.0 - featherHalf, st0.y);
                
                float prIn = mix(0., 0.8, u_prIn); // account for rotation
                float prOut = mix(0., 0.8, u_prOut); // account for rotation
                
                float featherStartIn = prIn - featherHalf;
                float featherEndIn = prIn + featherHalf;
                
                float featherStartOut = prOut - featherHalf;
                float featherEndOut = prOut + featherHalf;
                
                // Smoothstep to create a smooth transition for both prIn and prOut
                float maskIn = smoothstep(featherStartIn, featherEndIn, distFromEdge);
                float maskOut = smoothstep(featherStartOut, featherEndOut, distFromEdge);
                
                // Combine prIn and prOut: "in" reveals the screen and "out" hides it
                float mask = mix(maskOut, 1.0 - maskIn, maskIn); // Mix the in and out masks
                float maskBright = smoothstep(0.2, 0.8, mask);
                
                vec4 tex = texture2D(tMap, vUv);
                
                vec3 c0 = colorRamp(1. - mask, u_palette_noise);
                     c0.rgb = NeutralToneMapping0(c0.rgb);
                     c0 =  brightnessContrast(c0, maskBright * 0.5, 1.);
                vec3 c1 = mix(u_colorBG, tex.rgb, tex.a);
                vec3 c = mix(c0, c1, smoothstep(feather , 1., mask));

                float a = mask;

                gl_FragColor = vec4(c, a);
                gl_FragColor = gamma2linear(gl_FragColor);
                // debug
                // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
            }
        `,
        options: {
            depthTest: false,
            depthWrite: false,
        },
    });

    const updateTweens = () => {};

    useRafBool(canRender, ({ delta }) => {
        // set clear alpha to 0 and reset back to previous value after render
        const prevClearAlpha = renderer.getClearAlpha();
        renderer.setClearAlpha(0);

        // set render target to cutaway fbo and reset back to previous value after render
        const prevTarget = renderer.getRenderTarget();
        orthoCamera.layers.set(LAYER_FG);
        renderer.setRenderTarget(fbo);
        renderer.clear();
        renderer.render(sceneCutaway, orthoCamera);
        renderer.setRenderTarget(prevTarget);

        renderer.setClearAlpha(prevClearAlpha);

        material.uniforms.u_time.value += delta / 1000;
    });

    useViewportResize(({ width, height }) => {
        const dpr = renderer.getPixelRatio();
        fbo.setSize(width * dpr, height * dpr);
        material.u_resolution.set(width, height);
    }, true);

    const show = () => {
        // console.log('🚀 ~ hide ~ Cutaway: show:');
        const tl = gsap
            .timeline({ onUpdate: updateTweens })
            .fromTo(inPr, { value: 0 }, { value: 1, duration: IN_DUR * 1.5, ease: IN_EASE }, 0);
        return tl;
    };

    const hide = () => {
        // console.log('🚀 ~ hide ~ Cutaway: hide:');
        const tl = gsap
            .timeline({
                onUpdate: updateTweens,
                onComplete: () => {
                    inPr.value = 0;
                    outPr.value = 0;
                },
            })
            .fromTo(outPr, { value: 0 }, { value: 1, duration: OUT_DUR, ease: OUT_EASE }, 0);
        return tl;
    };

    watchEffect(() => {
        material.uniforms.u_prIn.value = inPr.value;
        material.uniforms.u_prOut.value = outPr.value;
    });

    onBeforeUnmount(() => {
        material.dispose();
        fbo.dispose();
    });

    // onUnmounted(() => object.parent?.remove(object));

    usePane(
        [
            { name: 'inPr', value: inPr, options: { min: 0, max: 1, step: 0.01 } },
            { name: 'outPr', value: outPr, options: { min: 0, max: 1, step: 0.01 } },
        ],
        {
            title: 'Cutaway',
            expanded: true,
        }
    );

    defineExpose({ show, hide });
</script>
