<template>
    <Line
        ref="refLines"
        v-for="(_, i) in refLineProps"
        :text="_.text"
        :key="`line${i}`"
        :layer="LAYER_FG"
        :bounds="textBounds"
        :position="_.position"
        :sign="i % 2 === 0 ? 1 : -1"
        :debug="false"
    />
</template>

<script setup>
    import { computed, ref, watch, watchEffect } from 'vue';

    import { useViewportResize } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import Line from './Line.vue';
    import { trimText } from './utils';
    import { useBounds } from '~/components/gl/Bounds';
    import { useCutawayObject } from '~/composables/useCutawayObject';
    import { LAYER_FG } from '~/core/constants';

    const props = defineProps({
        text: { default: ['Cutaway', 'BBBB', 'Text'] },
    });
    const { object: objectCutaway } = useCutawayObject(null, { name: 'CutwawayB' });

    const visible = ref(false);

    const totalLines = computed(() => refLineProps.value.length);
    const viewport = useViewportResize(() => resize(), true);

    const refLines = ref([]);
    const refLineProps = ref([]);

    const show = ({ delay = 0 } = {}) => {
        const tl = gsap.timeline({
            delay,
            onStart: () => (visible.value = true),
            onComplete: () => (visible.value = false),
        });
        refLines.value.forEach((refLine, i) => {
            const { show: showLine } = refLine;
            tl.add(showLine(), i * 0.04);
        });
        return tl;
    };

    const setVisible = () => (objectCutaway.visible = visible.value);

    useBounds(({ x, y }) => {
        objectCutaway.position.set(x, y, 0);
    });

    const resize = () => {
        const heightLines = textBounds.value.height * totalLines.value;
        refLineProps.value.forEach((_, i) => {
            _.position.y = heightLines / 2 - i * textBounds.value.height;
        });
    };

    const textBounds = computed(() => {
        const { width, height } = viewport;
        const tH = height * 0.2;
        const tW = width;
        return { width: tW, height: tH };
    });

    const refreshText = () => {
        const lines = props.text.slice(0, 3);

        refLineProps.value = lines.map((text) => ({
            text: trimText(text, 7),
            position: { x: 0, y: 0 },
        }));

        resize();
    };

    watchEffect(refreshText);
    watchEffect(setVisible);

    defineExpose({ show });
</script>
