<template>
    <GLText
        ref="refEyebrow"
        :text="trimText(subtitle, 35)"
        :layer="LAYER_FG"
        :options="{ fontSize: textBounds.height * 0.25, anchorY: 'bottom' }"
    />
    <Line
        ref="refLines"
        v-for="(_, i) in refLineProps"
        :text="_.text"
        :key="`line${i}`"
        :layer="LAYER_FG"
        :sign="0"
        :bounds="textBounds"
        :position="_.position"
        :debug="false"
    />
    <GLText
        ref="refCaption"
        :text="trimText(caption, 60)"
        :layer="LAYER_FG"
        :use-basesize-rescale="false"
        :options="{
            fontSize: 11,
            font: '/fonts/abc-diatype/ABCDiatypeMono-Medium-Trial.woff',
            anchorY: 'top',
            maxWidth: textBounds.width * 0.6,
            lineHeight: 1.2,
        }"
    />
</template>

<script setup>
    import { computed, ref, watchEffect } from 'vue';

    import { useViewportResize } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import Line from './Line.vue';
    import { trimText } from './utils';
    import { useBounds } from '~/components/gl/Bounds';
    import GLText from '~/components/gl/GLText.vue';
    import { useCutawayObject } from '~/composables/useCutawayObject';
    import { IN_DUR, IN_EASE, LAYER_FG, OUT_DUR, OUT_EASE, OUT_START } from '~/core/constants';

    const props = defineProps({
        subtitle: { default: 'Eyebrow', optional: true },
        title: { default: ['North', 'Carolina'] },
        caption: {
            default:
                'Culpa in anim aliqua ex qui quis ut anim consectetur nisi esse mollit commodo.',
            optional: true,
        },
    });

    const visible = ref(false);

    const refLines = ref([]);
    const refLineProps = ref([]);
    const refEyebrow = ref();
    const refCaption = ref();

    const totalLines = computed(() => refLineProps.value.length);

    const { object: objectCutaway } = useCutawayObject(null, { name: 'CutwawayC' });
    const viewport = useViewportResize(() => resize(), true);

    const show = ({ delay = 0 } = {}) => {
        const tl = gsap.timeline({
            delay,
            onStart: () => (visible.value = true),
            onComplete: () => (visible.value = false),
            onUpdate: () => updateLetters(),
        });
        refLines.value.forEach((refLine, i) => {
            const { show: showLine } = refLine;
            tl.add(showLine({ type: 'vertical' }), i * 0.04);
        });
        if (refEyebrow.value) {
            const { letters: lettersEyebrow } = refEyebrow.value;
            const lettersEyebrowPosArr = lettersEyebrow.map((l) => l.position);
            tl.fromTo(
                lettersEyebrow,
                { alpha: 0 },
                { alpha: 1, duration: IN_DUR, ease: IN_EASE, stagger: 0.01 },
                0.2
            )
                .fromTo(
                    lettersEyebrowPosArr,
                    { y: -8 },
                    { y: 0, duration: IN_DUR, ease: IN_EASE, stagger: 0.01 },
                    0.2
                )
                .to(
                    lettersEyebrow,
                    {
                        alpha: 0,
                        duration: OUT_DUR,
                        ease: OUT_EASE,
                        stagger: {
                            each: 0.02,
                            from: 'end',
                        },
                    },
                    OUT_START - 0.2
                );
        }
        if (refCaption.value) {
            const { letters: lettersCaption } = refCaption.value;
            tl.set(lettersCaption, { alpha: 0 }, 0)
                .to(
                    lettersCaption,
                    { alpha: 1, duration: 1, ease: 'sine.out', stagger: 0.008 },
                    0.2
                )
                .to(
                    lettersCaption,
                    {
                        alpha: 0,
                        duration: OUT_DUR / 1.5,
                        ease: OUT_EASE,
                    },
                    OUT_START
                );
        }

        return tl;
    };

    const updateLetters = () => {
        if (refEyebrow.value) refEyebrow.value.updateLetters();
        if (refCaption.value) refCaption.value.updateLetters();
    };

    const setVisible = () => {
        objectCutaway.visible = visible.value;
    };

    useBounds(({ x, y }) => {
        objectCutaway.position.set(x, y, 0);
    });

    const resize = () => {
        const linesHeight = textBounds.value.height * totalLines.value;
        const lineHeight = 0.95;
        refLineProps.value.forEach((_, i) => {
            _.position.y = linesHeight / 2 - i * textBounds.value.height * lineHeight;
        });
        if (refEyebrow.value) {
            const { object: objectEyebrow } = refEyebrow.value;
            objectEyebrow.position.y = linesHeight / 2 + 20;
        }
        if (refCaption.value) {
            const { object: objectCaption } = refCaption.value;
            objectCaption.position.y = -linesHeight / 2 - textBounds.value.height * 0.2;
            objectCaption.scale.setScalar(textBounds.value.height / 100);
        }
    };

    const textBounds = computed(() => {
        const { width, height } = viewport;
        const tH = height * 0.125;
        const tW = width;
        return { width: tW, height: tH };
    });

    const refreshText = () => {
        const lines = props.title.slice(0, 2);

        refLineProps.value = lines.map((text) => ({
            text: trimText(text, 10),
            position: { x: 0, y: 0 },
        }));
        resize();
    };

    watchEffect(refreshText);
    watchEffect(setVisible);

    defineExpose({ show });
</script>
