import glslDesaturate from '@/libs/lygia/color/desaturate.glsl';
import glslSnoise from '@/libs/lygia/generative/snoise.glsl';

export const uniforms = () => {
    return {
        u_palette_noise: { value: [] },
    };
};
export const shaderChunks = {
    uniforms: /* glsl */ `
        #ifndef NUM_COLORS
        #define NUM_COLORS 5
        #endif
        uniform vec3 u_palette_noise[NUM_COLORS];
    `,
    funcs: /* glsl */ `
        ${glslDesaturate}
        ${glslSnoise}

        vec3 palette[NUM_COLORS];
        // const vec3 palette = u_palette; // OR array of vec3 colors
        vec3 colorRamp(in float t, in vec3 palette[NUM_COLORS]) {
            vec3 c = palette[0];
            for(int i = 0; i < NUM_COLORS - 1; ++ i) {
                float s = float(i);
                float d = 1.0 / float(NUM_COLORS - 1);
                c = mix(c, palette[i + 1], smoothstep(s * d, (s + 1.0) * d, 1.0 - t));
            }
            return c;
        }
        
        vec3 iridescentNoise(vec3 viewDir, vec3 st, vec3 normal, vec3 palette[NUM_COLORS], bool desaturated) {
            vec3 n = snoise3(st);
            n = n * 0.5 + 0.5;
            normal += n;
            float factor = dot(normalize(viewDir), normal) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks
            vec3 res = colorRamp(factor, palette);
            if (desaturated) res = desaturate(res, 1.0);
            return res;
        }
    `,
};
